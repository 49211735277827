@font-face {
  font-family: "Fixel Black";
  src: url("./assets/fonts/FixelDisplay-Black.woff2") format("woff");
}

@font-face {
  font-family: "Fixel Bold";
  src: url("./assets/fonts/FixelDisplay-Bold.woff2") format("woff");
}

@font-face {
  font-family: "Fixel ExtraBold";
  src: url("./assets/fonts/FixelDisplay-ExtraBold.woff2") format("woff");
}

@font-face {
  font-family: "Fixel ExtraLight";
  src: url("./assets/fonts/FixelDisplay-ExtraLight.woff2") format("woff");
}

@font-face {
  font-family: "Fixel Light";
  src: url("./assets/fonts/FixelDisplay-Light.woff2") format("woff");
}

@font-face {
  font-family: "Fixel Medium";
  src: url("./assets/fonts/FixelDisplay-Medium.woff2") format("woff");
}

@font-face {
  font-family: "Fixel Regular";
  src: url("./assets/fonts/FixelDisplay-Regular.woff2") format("woff");
}

@font-face {
  font-family: "Fixel SemiBold";
  src: url("./assets/fonts/FixelDisplay-SemiBold.woff2") format("woff");
}

@font-face {
  font-family: "Fixel Thin";
  src: url("./assets/fonts/FixelDisplay-Thin.woff2") format("woff");
}

@font-face {
  font-family: "FixelText Black";
  src: url("./assets/fonts/FixelText-Black.woff2") format("woff");
}

@font-face {
  font-family: "FixelText Bold";
  src: url("./assets/fonts/FixelText-Bold.woff2") format("woff");
}

@font-face {
  font-family: "FixelText ExtraBold";
  src: url("./assets/fonts/FixelText-ExtraBold.woff2") format("woff");
}

@font-face {
  font-family: "FixelText ExtraLight";
  src: url("./assets/fonts/FixelText-ExtraLight.woff2") format("woff");
}

@font-face {
  font-family: "FixelText Light";
  src: url("./assets/fonts/FixelText-Light.woff2") format("woff");
}

@font-face {
  font-family: "FixelText Medium";
  src: url("./assets/fonts/FixelText-Medium.woff2") format("woff");
}

@font-face {
  font-family: "FixelText Regular";
  src: url("./assets/fonts/FixelText-Regular.woff2") format("woff");
}

@font-face {
  font-family: "FixelText SemiBold";
  src: url("./assets/fonts/FixelText-SemiBold.woff2") format("woff");
}

@font-face {
  font-family: "FixelText Thin";
  src: url("./assets/fonts/FixelText-Thin.woff2") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
